import * as React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";

// markup
const NotFoundPage = ({ location, data }) => {
  const seo = {
    tags: [
      {
        tagName: "title",
        content: "🧐",
      },
    ],
  };

  return (
    <Layout location={location} seo={seo} logo={global.logo}>
      <main
        css={`
          height: 100vh;
          padding-top: 120px;
        `}
      >
        <section
          css={`
            flex-direction: column;
          `}
        >
          <title>Not found</title>
          <h1>Page not found</h1>
          <p>
            Sorry{" "}
            <span role="img" aria-label="Pensive emoji">
              😔
            </span>{" "}
            we couldn’t find what you were looking for.
            <br />
            {process.env.NODE_ENV === "development" ? (
              <>
                <br />
                Try creating a page in <code>src/pages/</code>.
                <br />
              </>
            ) : null}
            <br />
            <Link to="/">Go to home page :)</Link>
          </p>
        </section>
      </main>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query nopageQuery {
    global: datoCmsGlobal {
      logo {
        fluid(maxWidth: 700) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
    }
  }
`;
